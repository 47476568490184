import {Link, useNavigate} from 'react-router-dom';
import {ErrorMessage, Formik} from 'formik';
import {useEffect} from 'react';
import {ACCESS_TOKEN_KEY, apiClient, REFRESH_TOKEN_KEY} from '../../api/client';
import * as Yup from 'yup';

export default function Login() {
  document.body.classList.add('login');
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelectorAll('.label-flotante .form-control').forEach((input) => {
      input.addEventListener('focus', () => {
        input.closest('.label-flotante').classList.add('activo');
      });
      input.addEventListener('blur', () => {
        if ('' === input.value) {
          input.closest('.label-flotante').classList.remove('activo');
        }
      });
    });
  }, []);

  const schema = Yup.object().shape({
    email: Yup.string()
      .min(2, 'Muy corto')
      .email('Formato inválido')
      .required('Requerido'),
    password: Yup.string()
      .min(8, 'Muy corto')
      .required('Requerido'),
  });

  const onSubmit = async (values, action) => {
    try {
      const response = await apiClient.post('auth/login', values);

      localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);

      navigate('/app');
    } catch (error) {
        action.setErrors({password: 'Credenciales inválidas'});
    } finally {
      action.setSubmitting(false);
    }
  };

  const Form = ({handleSubmit, isSubmitting, values, handleChange}) => (
    <form method="post" className="text-start animate" data-animation-type="slide-up" onSubmit={handleSubmit}>
      <div className="label-flotante">
        <label htmlFor="email" className="form-label">Email o usuario</label>
        <input type="email" className="form-control" id="email" name="email" value={values.email} onChange={handleChange}/>
        <ErrorMessage component="span" className="text-danger" name="email"/>
      </div>
      <div className="label-flotante">
        <label htmlFor="password" className="form-label">Contraseña</label>
        <input type="password" className="form-control" id="password" name="password" value={values.password} onChange={handleChange}/>
        <ErrorMessage component="span" className="text-danger" name="password"/>
      </div>

      <button type="submit" className={`btn btn-primary w-100${isSubmitting ? ' disabled' : ''}`}>Accede</button>
    </form>
  );

  return (
    <>
      <div className="head-bar active">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              <Link to="/" className="back" data-target="info" id="nav-info">
                <img alt="volver" src="/images/ico_back.svg" title="Back"/>
              </Link>
              <h2>Login</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="site-content mt-3">

        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            <div className="col-12 col-md-7 text-center">

              <div className="site-branding_logo animate" data-animation-type="fadeIn">
                <img alt="logo" src="/images/logo.svg" title="Space The 360"/>
              </div>

              <p className="texto animate" data-animation-type="fadeIn">Accede con tu email y contraseña.
                Si no tienes uno, puedes <Link to="/registro" className="link">registrarte aquí</Link></p>

              <Formik
                component={Form}
                initialValues={{email: '', password: ''}}
                onSubmit={onSubmit}
                validationSchema={schema}
              />

              <p className="animate" data-animation-type="fadeIn" data-animation-delay="600">
                <Link to="/registro" className="link">O regístrate si aún no tienes cuenta</Link>
              </p>

            </div>
          </div>
        </div>

      </div>
    </>
  );
}
