import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import {ACCESS_TOKEN_KEY, apiClient, REFRESH_TOKEN_KEY} from '../api/client';
import {Link, useNavigate} from 'react-router-dom';

export default function Account() {
  const navigate = useNavigate();
  const [modalLogoutOpen, setModalLogoutOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [errorDeleteAccount, setErrorDeleteAccount] = useState('');

  const handleLogoutOpen = () => setModalLogoutOpen(true);
  const handleLogoutClose = () => setModalLogoutOpen(false);
  const handleDeleteOpen = () => setModalDeleteOpen(true);
  const handleDeleteClose = () => setModalDeleteOpen(false);

  const logout = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    navigate('/');
  };

  const deleteAccount = async (password) => {
    try {
      await apiClient.delete('user', {password: password});

      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);

      navigate('/');

    } catch (error) {
      const errorJson = JSON.parse(error.message);
      setErrorDeleteAccount(errorJson.body.errors.password)
      console.log(error);
    }
  };

  return (
    <>
      <div className="site-content div-user">
        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            <div className="col-12 col-md-7">
              <ul>
                {/*<li><a href="#">Cambiar tu contraseña</a></li>*/}
                {/*<li><a href="#">Cambiar tu e-mail</a></li>*/}
                <li><Link to="/app/politica-privacidad">Política de Privacidad</Link></li>
                <li><Link to="/app/aviso-legal">Aviso legal</Link></li>
                <li><a href="#" onClick={(e) => {
                  e.preventDefault();
                  handleDeleteOpen();
                }}>Eliminar tu cuenta</a></li>
                <li><a href="#" className="text-danger" onClick={(e) => {
                  e.preventDefault();
                  handleLogoutOpen();
                }}>Cerrar sesión</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Modal show={modalLogoutOpen} onHide={handleLogoutClose} centered>
        <Modal.Body>
          <div className="tit">¿Estás seguro de que quieres cerrar la sesión?</div>
        </Modal.Body>
        <Modal.Footer>
          <a href="#" className="btn-pop red" onClick={(e) => {
            e.preventDefault();
            logout();
          }}>Cerrar sesión</a>
        </Modal.Footer>
        <Modal.Footer>
          <a href="#" className="btn-pop" onClick={(e) => {
            e.preventDefault();
            handleLogoutClose();
          }}>Cancelar</a>
        </Modal.Footer>
      </Modal>

      <Modal show={modalDeleteOpen} onHide={handleDeleteClose} centered>
        <Modal.Body>
          <div className="tit">¿Estás seguro de que quieres eliminar tu cuenta?</div>
          <div className="mb-3">Esto borrará tu perfil e información de nuestra plataforma. Esta acción es irreversible.</div>
          <div>
            Introduce tu contraseña para confirmar:<br/><br/>
            <input type="password" name="deletePassword" id="deletePassword" className="form-control"/>
            {errorDeleteAccount && <span className="text-danger">{errorDeleteAccount}</span>}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <a href="#" className="btn-pop red" onClick={(e) => {
            e.preventDefault();
            deleteAccount(document.getElementById("deletePassword").value);
          }}>Eliminar mi cuenta</a>
        </Modal.Footer>
        <Modal.Footer>
          <a href="#" className="btn-pop" onClick={(e) => {
            e.preventDefault();
            handleDeleteClose();
          }}>Cancelar</a>
        </Modal.Footer>
      </Modal>
    </>
  );
}
