const API_BASE_URI = process.env.REACT_APP_API_BASE_URI

export const ERRORS = {
  SERVER: 'server',
  UNAUTHORIZED: 'unauthorized',
}

export const ACCESS_TOKEN_KEY = 'festival:auth:access_token';
export const REFRESH_TOKEN_KEY = 'festival:auth:refresh_token';

export const apiClient = {
  get(endpoint) {
    return request(endpoint, 'GET', {})
  },
  post(endpoint, payload) {
    return request(endpoint, 'POST', payload)
  },
  delete(endpoint, payload) {
    return request(endpoint, 'DELETE', payload)
  }
}

async function request(endpoint, method, payload) {
  let options = {
    method,
    headers: {
      'Content-Type': 'application/json',
    }
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`;
  }

  if ('undefined' !== typeof payload && Object.keys(payload).length > 0) {
    options.body = JSON.stringify(payload)
  }

  console.log(endpoint);
  const response = await fetch(`${API_BASE_URI}${endpoint}`, options)
  const status = response.status.toString()
  console.log(endpoint, status);

  if ('410' === status) {
    await refreshToken(options);
    return request(endpoint, method, payload);
  }

  return await checkErrorsAndGetJson(status, response)
}

async function refreshToken(options) {
  delete options.headers.Authorization;
  options.method = 'POST';
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
  options.body = JSON.stringify({refreshToken});

  const response = await fetch(`${API_BASE_URI}auth/refresh-token`, options);
  const status = response.status.toString();
  console.log('/refresh-token', status);

  const json = await checkErrorsAndGetJson(status, response);
  localStorage.setItem(ACCESS_TOKEN_KEY, json.accessToken);
  localStorage.setItem(REFRESH_TOKEN_KEY, json.refreshToken);
}

async function checkErrorsAndGetJson(status, response) {
  const statusGroup = status.charAt(0);
  if ('5' === statusGroup) {
    throw new Error(ERRORS.SERVER);
  }

  if ('401' === status || '403' === status) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
    throw new Error(ERRORS.UNAUTHORIZED);
  }

  if ('204' === status) {
    return null;
  }

  const json = await response.json();

  if ('4' === statusGroup) {
    throw new Error(JSON.stringify({
      statusCode: status,
      body: json,
    }));
  }

  return json;
}
