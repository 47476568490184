import {useOutletContext} from 'react-router-dom';
import {useEffect} from 'react';
import Hammer from 'hammerjs';

export default function Map() {
  const {info} = useOutletContext();

  useEffect(() => {
    let MIN_SCALE = 1;
    let MAX_SCALE = 64;

    let imgWidth = null;
    let imgHeight = null;
    let viewportWidth = null;
    let viewportHeight = null;
    let scale = null;
    let lastScale = null;
    let container = null;
    let img = null;
    let x = 0;
    let lastX = 0;
    let y = 0;
    let lastY = 0;
    let pinchCenter = null;

    let curWidth = 2000;
    let curHeight = 1000;
    let pinchCenterOffset = {x: 150, y: 150};

    const disableImgEventHandlers = function () {
      const events = ['onclick', 'onmousedown', 'onmousemove', 'onmouseout', 'onmouseover', 'onmouseup', 'ondblclick', 'onfocus', 'onblur'];

      events.forEach(function (event) {
        img[event] = function () {
          return false;
        };
      });
    };

    const absolutePosition = function (el) {
      let x = 0;
      let y = 0;

      while (el !== null) {
        x += el.offsetLeft;
        y += el.offsetTop;
        el = el.offsetParent;
      }

      return { x: x, y: y };
    };

    const restrictScale = function (scale) {
      if (scale < MIN_SCALE) {
        scale = MIN_SCALE;
      } else if (scale > MAX_SCALE) {
        scale = MAX_SCALE;
      }
      return scale;
    };

    const restrictRawPos = function (pos, viewportDim, imgDim) {
      if (pos < viewportDim/scale - imgDim) { // too far left/up?
        pos = viewportDim/scale - imgDim;
      } else if (pos > 0) { // too far right/down?
        pos = 0;
      }
      return pos;
    };

    const updateLastPos = function (deltaX, deltaY) {
      lastX = x;
      lastY = y;
    };

    const translate = function (deltaX, deltaY) {
      // We restrict to the min of the viewport width/height or current width/height as the
      // current width/height may be smaller than the viewport width/height

      const newX = restrictRawPos(lastX + deltaX/scale, Math.min(viewportWidth, curWidth), imgWidth);
      x = newX;
      img.style.marginLeft = Math.ceil(newX*scale) + 'px';

      const newY = restrictRawPos(lastY + deltaY/scale, Math.min(viewportHeight, curHeight), imgHeight);
      y = newY;
      img.style.marginTop = Math.ceil(newY*scale) + 'px';
    };

    const zoom = function (scaleBy) {
      scale = restrictScale(lastScale*scaleBy);

      const curWidth = imgWidth*scale;
      const curHeight = imgHeight*scale;

      img.style.width = Math.ceil(curWidth) + 'px';
      img.style.height = Math.ceil(curHeight) + 'px';

      // Adjust margins to make sure that we aren't out of bounds
      translate(0, 0);
    };

    const rawCenter = function (e) {
      const pos = absolutePosition(container);

      // We need to account for the scroll position
      const scrollLeft = window.pageXOffset ? window.pageXOffset : document.body.scrollLeft;
      const scrollTop = window.pageYOffset ? window.pageYOffset : document.body.scrollTop;

      const zoomX = -x + (e.center.x - pos.x + scrollLeft)/scale;
      const zoomY = -y + (e.center.y - pos.y + scrollTop)/scale;

      return { x: zoomX, y: zoomY };
    };

    const updateLastScale = function () {
      lastScale = scale;
    };

    const zoomAround = function (scaleBy, rawZoomX, rawZoomY, doNotUpdateLast) {
      // Zoom
      zoom(scaleBy);

      // New raw center of viewport
      const rawCenterX = -x + Math.min(viewportWidth, curWidth)/2/scale;
      const rawCenterY = -y + Math.min(viewportHeight, curHeight)/2/scale;

      // Delta
      const deltaX = (rawCenterX - rawZoomX)*scale;
      const deltaY = (rawCenterY - rawZoomY)*scale;

      // Translate back to zoom center
      translate(deltaX, deltaY);

      if (!doNotUpdateLast) {
        updateLastScale();
        updateLastPos();
      }
    };

    const zoomCenter = function (scaleBy) {
      // Center of viewport
      const zoomX = -x + Math.min(viewportWidth, curWidth)/2/scale;
      const zoomY = -y + Math.min(viewportHeight, curHeight)/2/scale;

      zoomAround(scaleBy, zoomX, zoomY);
    };

    const zoomIn = function () {
      zoomCenter(2);
    };

    const zoomOut = function () {
      zoomCenter(1/2);
    };

    const onLoad = function () {
      img = document.getElementById('pinch-zoom-image-id');
      container = img.parentElement;

      disableImgEventHandlers();

      imgWidth = img.width;
      imgHeight = img.height;

      viewportWidth = img.parentElement.offsetWidth;
      scale = 1; //viewportWidth/imgWidth;
      lastScale = scale;
      viewportHeight = img.parentElement.offsetHeight;
      curWidth = imgWidth*scale;
      curHeight = imgHeight*scale;

      const hammer = new Hammer(container, {
        domEvents: true,
        inertia: true,
        edgeResistance: 1 // Don’t allow any dragging beyond the bounds
      });

      hammer.get('pinch').set({
        enable: true
      });

      hammer.on('pan', function (e) {
        translate(e.deltaX, e.deltaY);
      });

      hammer.on('panend', function (e) {
        updateLastPos();
      });

      hammer.on('pinch', function (e) {

        // We only calculate the pinch center on the first pinch event as we want the center to
        // stay consistent during the entire pinch
        if (pinchCenter === null) {
          pinchCenter = rawCenter(e);
          const offsetX = pinchCenter.x*scale - (-x*scale + Math.min(viewportWidth, curWidth)/2);
          const offsetY = pinchCenter.y*scale - (-y*scale + Math.min(viewportHeight, curHeight)/2);
          pinchCenterOffset = { x: offsetX, y: offsetY };
        }

        // When the user pinch zooms, she/he expects the pinch center to remain in the same
        // relative location of the screen. To achieve this, the raw zoom center is calculated by
        // first storing the pinch center and the scaled offset to the current center of the
        // image. The new scale is then used to calculate the zoom center. This has the effect of
        // actually translating the zoom center on each pinch zoom event.
        const newScale = restrictScale(scale*e.scale);
        const zoomX = pinchCenter.x*newScale - pinchCenterOffset.x;
        const zoomY = pinchCenter.y*newScale - pinchCenterOffset.y;
        const zoomCenter = { x: zoomX/newScale, y: zoomY/newScale };

        zoomAround(e.scale, zoomCenter.x, zoomCenter.y, true);
      });

      hammer.on('pinchend', function (e) {
        updateLastScale();
        updateLastPos();
        pinchCenter = null;
      });

    };

    // onLoad();
    window.setTimeout(onLoad, 500);
  }, []);

  return (
    <div className="pinch-zoom-container">
      <img alt="Mapa" id="pinch-zoom-image-id" src={info?.map} className="animate" data-animation-type="fadeIn"/>
    </div>
  );
}
