import {useEffect, useState} from 'react';
import {apiClient} from '../api/client';

export default function TextPrivacy() {
  const [text, setText] = useState('');

  const loadData = async () => {
    try {
      const info = await apiClient.get('text/privacy-policy');
      setText(info);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div className="site-content div-user">
      <div className="container h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 col-md-7 mt-3">
            <div className="text" dangerouslySetInnerHTML={{__html: text?.text}}></div>
          </div>
        </div>
      </div>
    </div>
  );
}
