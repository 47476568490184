import {useOutletContext} from 'react-router-dom';
import {Fragment} from 'react';

export default function Music() {
  const {info} = useOutletContext();

  return (
    <div className="site-content div-music">

      <div className="container">
        <div className="row justify-content-center">

          <div className="col-12 col-md-7">

            <div className="cards">

              {info?.schedule.map((schedule, index) => (
                <Fragment key={index}>
                  <div className="cat">{schedule.name}</div>
                  <div className="room">
                    {schedule.artists.map((artist, index) => (
                      <div className="item" key={index}>
                        <div className="img"><img alt={artist.name} src={artist.photo}/></div>
                        <div className="text">
                          <div className="hora">{artist.timeStart} - {artist.timeEnd}</div>
                          <div className="artista">{artist.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}
