import {useEffect, useState} from 'react';
import {apiClient} from '../api/client';
import {Link} from 'react-router-dom';

export default function TextLegalPublic() {
  document.body.classList.add('app');
  const [text, setText] = useState('');

  const loadData = async () => {
    try {
      const info = await apiClient.get('text/legal-notice');
      setText(info);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <div className="head-bar active">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              <Link to="/registro" className="back" data-target="info" id="nav-info">
                <img alt="volver" src="/images/ico_back.svg" title="Back"/>
              </Link>
              <h2>Aviso legal</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="content">
        <div className="site-content div-user">
          <div className="container h-100">
            <div className="row h-100 justify-content-center">
              <div className="col-12 col-md-7 mt-3">
                <div className="text" dangerouslySetInnerHTML={{__html: text?.text}}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
