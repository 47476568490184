import {Link, useNavigate} from 'react-router-dom';
import {ErrorMessage, Formik} from 'formik';
import {useEffect} from 'react';
import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, apiClient} from '../../api/client';
import * as Yup from 'yup';

export default function RegisterConfirmation() {
  document.body.classList.add('login');
  const navigate = useNavigate();

  useEffect(() => {
    const inputs = document.querySelectorAll('.label-flotante .form-control');
    inputs.forEach((input) => {
      input.addEventListener('focus', () => {
        input.closest('.label-flotante').classList.add('activo');
      });
      input.addEventListener('blur', () => {
        if ('' === input.value) {
          input.closest('.label-flotante').classList.remove('activo');
        }
      });
    });
  }, []);

  const schema = Yup.object().shape({
    code: Yup.string()
      .length(6, 'Debe tener 6 carácteres')
      .required('Requerido'),
  });

  const onSubmit = async (values, action) => {
    try {
      const response = await apiClient.post('auth/register/confirmation', values);

      localStorage.setItem(ACCESS_TOKEN_KEY, response.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, response.refreshToken);

      navigate('/app');
    } catch (error) {
      const errorJson = JSON.parse(error.message);
      action.setErrors(errorJson.body.errors);
    } finally {
      action.setSubmitting(false);
    }
  };

  const Form = ({handleSubmit, isSubmitting, values, handleChange}) => (
    <form method="post" className="text-start animate" data-animation-type="slide-up" onSubmit={handleSubmit}>
      <div className="label-flotante">
        <label htmlFor="code" className="form-label">Código</label>
        <input type="number" className="form-control" id="code" name="code" value={values.code} onChange={handleChange}/>
        <ErrorMessage component="span" className="text-danger" name="code"/>
      </div>

      <button type="submit" className={`btn btn-primary w-100${isSubmitting ? ' disabled' : ''}`}>Regístrate</button>
    </form>
  );

  return (
    <div className="site-content">

      <div className="container h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 col-md-7 text-center">

            <div className="site-branding_logo animate" data-animation-type="fadeIn">
              <img alt="logo" src="/images/logo.svg" title="Space The 360"/>
            </div>

            <p className="texto animate" data-animation-type="fadeIn">Introduce a continuación el código que has recibido por email:</p>

            <Formik
              component={Form}
              initialValues={{code: ''}}
              onSubmit={onSubmit}
              validationSchema={schema}
            />

            <p className="animate" data-animation-type="fadeIn" data-animation-delay="600">
              <Link to="/" className="link">Volver</Link>
            </p>

          </div>
        </div>
      </div>

    </div>
  );
}
