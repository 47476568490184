import {Link, useNavigate} from 'react-router-dom';
import {ErrorMessage, Formik} from 'formik';
import {useEffect} from 'react';
import {apiClient} from '../../api/client';
import * as Yup from 'yup';

export default function Register() {
  document.body.classList.add('login');
  const navigate = useNavigate();

  useEffect(() => {
    document.querySelectorAll('.label-flotante .form-control').forEach((input) => {
      input.addEventListener('focus', () => {
        input.closest('.label-flotante').classList.add('activo');
      });
      input.addEventListener('blur', () => {
        if ('' === input.value) {
          input.closest('.label-flotante').classList.remove('activo');
        }
      });
    });
  }, []);

  const schema = Yup.object().shape({
    email: Yup.string()
      .min(2, 'Muy corto')
      .email('Formato inválido')
      .required('Requerido'),
    password: Yup.string()
      .min(8, 'Muy corto')
      .required('Requerido'),
    conditions: Yup.boolean()
      .oneOf([true], 'Requerido'),
  });

  const onSubmit = async (values, action) => {
    try {
      await apiClient.post('auth/register', {
        email: values.email,
        password: values.password,
      });

      navigate('/registro/confirmacion');
    } catch (error) {
      const errorJson = JSON.parse(error.message);
      action.setErrors(errorJson.body.errors);
    } finally {
      action.setSubmitting(false);
    }
  };

  const Form = ({handleSubmit, isSubmitting, values, handleChange}) => (
    <form method="post" className="text-start animate" data-animation-type="slide-up" onSubmit={handleSubmit}>
      <div className="label-flotante">
        <label htmlFor="email" className="form-label">Email o usuario</label>
        <input type="email" className="form-control" id="email" name="email" value={values.email} onChange={handleChange}/>
        <ErrorMessage component="span" className="text-danger" name="email"/>
      </div>
      <div className="label-flotante">
        <label htmlFor="password" className="form-label">Contraseña</label>
        <input type="password" className="form-control" id="password" name="password" value={values.password} onChange={handleChange}/>
        <ErrorMessage component="span" className="text-danger" name="password"/>
      </div>
      <div className="form-check">
        <input type="checkbox" className="form-check-input" id="conditions" name="conditions" value={values.condition} onChange={handleChange}/>
        <label className="form-check-label" htmlFor="conditions">Para registrarte tienes que leer
          y aceptar nuestro <Link to="/aviso-legal">Aviso Legal</Link> y <Link to="/politica-privacidad">Politica de Privacidad</Link>
        </label>
        <br/>
        <ErrorMessage component="span" className="text-danger" name="conditions"/>
      </div>

      <button type="submit" className={`btn btn-primary w-100${isSubmitting ? ' disabled' : ''}`}>Regístrate</button>
    </form>
  );

  return (
    <>
      <div className="head-bar active">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col text-center">
              <Link to="/" className="back" data-target="info" id="nav-info">
                <img alt="volver" src="/images/ico_back.svg" title="Back"/>
              </Link>
              <h2>Registro</h2>
            </div>
          </div>
        </div>
      </div>

      <div className="site-content mt-3">

        <div className="container h-100">
          <div className="row h-100 justify-content-center">
            <div className="col-12 col-md-7 text-center">

              <div className="site-branding_logo animate" data-animation-type="fadeIn">
                <img alt="logo" src="/images/logo.svg" title="Space The 360"/>
              </div>

              <p className="texto animate" data-animation-type="fadeIn">Regístrate y accede a toda la información del
                festival en tu móvil:</p>

              <Formik
                component={Form}
                initialValues={{email: '', password: '', conditions: false}}
                onSubmit={onSubmit}
                validationSchema={schema}
              />

              <p className="animate" data-animation-type="fadeIn" data-animation-delay="600">
                <Link to="/login" className="link">O accede con tus cuenta</Link>
              </p>

            </div>
          </div>
        </div>

      </div>
    </>
  );
}
